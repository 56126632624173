import { NetworkType } from "@/types/network-type";
import { hashStringToColor } from "@/utils/formatters";
import Image from "next/image";

type NetworkIconProps = {
	network: NetworkType | undefined;
	height?: number;
	width?: number;
};

export const NetworkIcon = ({ network, height = 40, width = 40 }: NetworkIconProps) => {
	return (
		<>
			{network?.logoURI ? (
				<Image
					src={network?.logoURI}
					alt={network.name}
					className="rounded-full"
					height={height}
					width={width}
				/>
			) : (
				<div
					className="text-xs font-bold rounded-full flex items-center justify-center"
					style={{
						width: `${width}px`,
						height: `${height}px`,
						background: hashStringToColor(`${network?.name ?? "??"} ${network?.chainId?.toString() ?? "??"}`),
					}}>
					<div>{network?.name?.substring(0, 2) ?? "🧐"}</div>
				</div>
			)}
		</>
	);
};
