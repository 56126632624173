import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";

import * as React from "react";
import { Loader2 } from "lucide-react";

import { cn } from "@/lib/utils";
import { IconComponent } from "../types";

const buttonVariants = cva(
	"cursor-pointer whitespace-nowrap inline-flex gap-2 items-center justify-center font-medium disabled:opacity-50 disabled:pointer-events-none ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ring-blue",
	{
		variants: {
			variant: {
				default:
					"bg-accent text-accent hover:bg-blue-600 focus:bg-blue-700 active:bg-blue-600 hover:text-blue-600 focus:text-blue-700 active:text-blue-600",
				destructive:
					"bg-destructive text-destructive hover:bg-red-600 focus:bg-red-700 active:bg-red-600 hover:text-red-600 focus:text-red-700 active:text-red-600",
				warning: "bg-amber-400 hover:bg-amber-500 focus:bg-amber-600 active:bg-amber-500 text-amber-900",
				outline: "!border border-accent bg-background hover:bg-muted hover:text-accent-foreground",
				secondary: "bg-foreground text-foreground",
				"secondary-no-nub": "bg-dialog-content hover:bg-secondary-background active:bg-border",
				ghost: "",
				link: "text-blue hover:underline hover:text-blue-700 font-semibold !p-0 !h-[unset] !min-h-[unset]",
				settings: "bg-[#DEE7F7] dark:bg-[#151D28] hover:bg-dialog-body",
				"always-dark":
					"bg-[#213145] dark:bg-[#1D2735E6] text-[#FAFAFA] hover:bg-[#213145E6] dark:hover:bg-[#1D2735] text-[#FAFAFA]",
				tertiary:
					"bg-[#B5C9ED] hover:bg-[#B5C9EDE6] text-[#B5C9ED] hover:text-[#B5C9EDE6] dark:bg-[#1D2735] dark:hover:bg-[#1D2735E6] dark:text-[#1D2735] dark:hover:text-[#1D2735E6]",
				regular: "text-primary bg-[#FAFAFA] text-[#FAFAFA]",
				badge: "text-[#1D2735] bg-[#1D2735]",
			},
			size: {
				xxs: "min-h-[20px] h-[20px] px-1 text-[10px]",
				xs: "min-h-[26px] h-[26px] px-2 text-xs",
				sm: "min-h-[36px] h-[36px] px-3 text-sm",
				default: "min-h-[44px] h-[44px] py-2 px-4 text-base",
				lg: "min-h-[44px] h-[44px] px-4",
				xl: "min-h-[52px] h-[52px] px-4",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

const buttonLoaderVariants = cva("animate-spin", {
	variants: {
		size: {
			xxs: "w-3 h-3",
			xs: "w-4 h-4",
			sm: "w-[18px] h-[18px]",
			default: "w-5 h-5",
			lg: "w-5 h-5 stroke-[2px]",
			xl: "w-5 h-5 stroke-[2px]",
		},
	},
	defaultVariants: {
		size: "default",
	},
});

const buttonIconVariants = cva("", {
	variants: {
		size: {
			xxs: "w-3 h-3",
			xs: "w-4 h-4",
			sm: "w-[18px] h-[18px]",
			default: "w-5 h-5",
			lg: "w-5 h-5",
			xl: "w-5 h-5",
		},
	},
	defaultVariants: {
		size: "default",
	},
});

const buttonNubVariants = cva("", {
	variants: {
		variant: {
			left: "absolute -left-[10px] top-1/2 -translate-y-1/2",
			right: "absolute -right-[10px] top-1/2 -translate-y-1/2",
		},
		size: {
			xxs: "w-[15px] h-[20px]",
			xs: "w-[14px] h-[26px]",
			sm: "w-[11px] h-[36px]",
			default: "w-[11px] h-[44px]",
			lg: "w-[11px] h-[44px]",
			xl: "w-[11px] h-[52px]",
		},
	},
	defaultVariants: {
		size: "default",
	},
});

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	icon?: IconComponent;
	iconProps?: Omit<React.ComponentProps<"svg">, "width" | "height">;
	iconPosition?: "start" | "end";
	asChild?: boolean;
	loading?: boolean;
	fullWidth?: boolean;
	testId?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			fullWidth,
			icon: Icon,
			iconProps,
			iconPosition = "start",
			disabled = false,
			className,
			variant,
			size,
			children,
			asChild = false,
			loading = false,
			testId,
			id,
			...props
		},
		ref
	) => {
		const Comp = asChild ? Slot : "button";
		const _variant = variant || "default";
		const hasNubs =
			_variant === "default" ||
			_variant === "destructive" ||
			_variant === "tertiary" ||
			_variant === "secondary" ||
			_variant === "regular" ||
			_variant === "badge";

		return (
			<Comp
				disabled={loading ? true : disabled}
				className={cn(
					buttonVariants({
						variant,
						size,
						className: cn(fullWidth ? "flex-1 w-full" : "", "relative ", className),
					})
				)}
				ref={ref}
				{...props}
				testdata-id={`${testId || id}-button`}>
				{hasNubs ? <LeftNub className={buttonNubVariants({ size, variant: "left" })} /> : null}
				<ButtonContent
					asChild={asChild}
					className={cn(
						_variant === "default" || _variant === "destructive" || _variant === "badge" ? "text-white" : "",
						_variant === "tertiary" || _variant === "secondary" || _variant === "regular"
							? " dark:text-[#ECEDEF] text-[#22262A]"
							: "",
						"flex items-center justify-center gap-1",
						className
					)}>
					{loading ? (
						<Loader2 className={buttonLoaderVariants({ size })} />
					) : Icon && iconPosition === "start" ? (
						<Icon
							{...iconProps}
							className={buttonIconVariants({
								size,
								className: iconProps?.className,
							})}
						/>
					) : null}
					{children}
					{Icon && iconPosition === "end" ? (
						<Icon
							{...iconProps}
							className={buttonIconVariants({
								size,
								className: iconProps?.className,
							})}
						/>
					) : null}
				</ButtonContent>
				{hasNubs ? <RightNub className={buttonNubVariants({ size, variant: "right" })} /> : null}
			</Comp>
		);
	}
);

Button.displayName = "Button";

interface ButtonContentProps extends React.HTMLAttributes<HTMLDivElement> {
	asChild?: boolean;
}

const ButtonContent = React.forwardRef<HTMLDivElement, ButtonContentProps>(function Button(
	{ asChild, children, ...props },
	ref
) {
	if (asChild) {
		return (
			<div className="inline-flex gap-1 text-white" ref={ref} {...props}>
				{children}
			</div>
		);
	}

	return (
		<span className="text-white" {...props}>
			{children}
		</span>
	);
});

const LeftNub: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 11 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10 0H11V7.7V16.5V27.5V36.3V44H10V36.3H5V27.5H0V16.5H5V7.7H10V0Z" fill="currentColor" />
	</svg>
);
const RightNub: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 11 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 0H0V44H1V36.3H6V27.5H11V16.5H6V7.7H1V0Z" fill="currentColor" />
	</svg>
);

export { Button, buttonIconVariants, buttonLoaderVariants, buttonVariants };
