import { Percent } from "sushi/math";
import dayjs from "dayjs";
import dayjsUTC from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import UTC from "dayjs/plugin/utc";
import { Type } from "sushi/currency";
dayjs.extend(relativeTime);
dayjsUTC.extend(UTC);

export const hashStringToColor = (str: string) => {
	const hash = _djb2(str);
	const r = (hash & 0xff0000) >> 16;
	const g = (hash & 0x00ff00) >> 8;
	const b = hash & 0x0000ff;
	return (
		"#" +
		("0" + r.toString(16)).substr(-2) +
		("0" + g.toString(16)).substr(-2) +
		("0" + b.toString(16)).substr(-2)
	);
};

const _djb2 = (str: string) => {
	let hash = 5381;
	for (let i = 0; i < str.length; i++) {
		hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
	}
	return hash;
};

export const formatDate = (date: string, formatString?: string) => {
	return dayjs(date).format(formatString ?? "MMM D, YYYY h:mm A");
};

export const formatTime = (pastTimestamp: number, formatString?: string) => {
	//if time is greater than 1 day, show the date and time
	if (dayjs().diff(pastTimestamp, "day") > 1) {
		return dayjs(pastTimestamp).format(formatString ?? "MMM D, YYYY h:mm A");
	}
	return dayjs(pastTimestamp).fromNow();
};

export const durationInDays = (startDate: Date | undefined, endDate: Date | undefined) => {
	if (!startDate || !endDate) {
		return 0;
	}

	const start = dayjs(startDate);
	const end = dayjs(endDate);
	return end.diff(start, "day");
};

export const getUTCTime = (date: Date | undefined) => {
	if (!date) {
		return "";
	}
	return dayjsUTC(date).utc().format("HH:mm");
};

export const formatUTCDate = ({
	timestamp,
	formatString,
	isBlockTimestamp,
}: {
	timestamp: number;
	formatString?: string;
	isBlockTimestamp?: boolean;
}) => {
	const date = isBlockTimestamp ? timestamp * 1000 : timestamp;
	return dayjsUTC(date)
		.utc()
		.format(formatString || "MMM D, YYYY");
};

export const formatDisplayAmount = (amount: string): string => {
	if (Number(amount) < 0.0001 && Number(amount) > 0) {
		return "<0.0001";
	}
	return amount;
};

export const formatSlippage = (slippageTolerance: string): Percent => {
	return new Percent(
		Math.floor(Number(slippageTolerance === "AUTO" ? "0.1" : slippageTolerance) * 100),
		10_000
	);
};

export const capitalize = (str: string) => str?.[0]?.toUpperCase() + str.slice(1);

export const sortCurrencies = (tokenA: Type, tokenB: Type) => {
	if (tokenA?.wrapped?.address === tokenB?.wrapped?.address) return [tokenA, tokenB];
	return tokenA?.wrapped?.sortsBefore(tokenB?.wrapped) ? [tokenA, tokenB] : [tokenB, tokenA];
};

export const shortenString = (str: string, length: number = 8) => {
	if (str.length <= length) return str;
	return str.slice(0, length) + "...";
};
