"use client";
import { NetworkType } from "@/types/network-type";
import Image from "next/image";
import { Badge } from "../components/badge";
import { NetworkIcon } from "./network-icon";
import { Currency } from "sushi/currency";
import { useMemo, useRef, useState } from "react";
import { DEFAULT_TOKEN_LIST } from "@/constants/tokens/default-token-list";
import { hashStringToColor } from "@/utils/formatters";
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "../components/avatar";

type TokenIconProps = {
	currency: Currency | undefined;
	height?: number;
	width?: number;
	network?: NetworkType;
};

export const TokenIcon = ({ currency, height = 40, width = 40, network }: TokenIconProps) => {
	return (
		<>
			{network ? (
				<Badge
					position="bottom-right"
					badgeContent={<NetworkIcon width={width / 2} height={height / 2} network={network} />}>
					<_Token currency={currency} height={height} width={width} />
				</Badge>
			) : (
				<_Token currency={currency} height={height} width={width} />
			)}
		</>
	);
};

const _Token = ({ currency, height = 40, width = 40 }: Partial<TokenIconProps>) => {
	const src = useMemo(() => {
		if (currency?.isNative) {
			return "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x4d224452801ACEd8B2F0aebE155379bb5D594381/logo.png";
		}
		const foundLogoURI = DEFAULT_TOKEN_LIST?.find(
			(token) => token?.address?.toLowerCase() === currency?.address?.toLowerCase()
		)?.logoURI;
		if (foundLogoURI) return foundLogoURI;
		return `https://dd.dexscreener.com/ds-data/tokens/apechain/${currency?.wrapped?.address?.toLowerCase()}.png?size=lg`;
	}, [currency]);

	return (
		<Avatar
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}>
			<AvatarImage src={src} alt="" height={height} width={width} />
			<AvatarFallback
				style={{
					backgroundColor: hashStringToColor(`${currency?.symbol ?? "sym"} ${currency?.name ?? "name"}`),
				}}>
				{currency?.symbol?.substring(0, 2) ?? "🧐"}
			</AvatarFallback>
		</Avatar>
	);
};
